<template>
  <div class="row">
    <div class="col-md-12">
      <h3>{{ step.title }}</h3>
      <p v-if="step.description" class="step-description">{{ step.description }}</p>
    </div>
    <div class="row options">
      <div v-for="option in options" class="col mt-2" :class="{ 'col-2': options.length > 6 }" :key="option.id">
        <component :is="(option.type || 'select') + 'Option'" :option="option" />
      </div>
    </div>
  </div>
</template>
<script>
import SelectOption from '@/pages/system/steps/option/selectOption'
import CountOption from '@/pages/system/steps/option/countOption'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
export default {
  components: {
    selectOption: SelectOption,
    countOption: CountOption,
    checkOption: SelectOption
  },
  setup () {
    const store = useStore()
    const options = computed(() => {
      return store.getters['system/currentOptions']
    })
    const step = computed(() => {
      return store.getters['system/currentStep']
    })
    return {
      options,
      step
    }
  }
}
</script>
